.App {
  text-align: center;
}

.App-logo {
  height: 100%;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.copyRightText {
  font-size: large;
  color: lightslategray;
  margin-bottom: 7em;
  margin-top: 2em;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.Banner {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 7em;
}
