.registration-all {
    text-align: center;
    margin-bottom: 8em;
}

.registration-body {
    text-align: left;
    width: 640px;
    margin: auto;
}

input[type="checkbox"] {
    margin-right: 0.6em;
}

.number-input-field {
    margin: 0.6em 0;
    font-size: 16px;
}

.error-label {
    display: none;
    color: #C01930;
}

.has-error .error-label {
    display: inline-block;
}

.hidden-label {
    display: none;
}

.show-error-label {
    display: inline-block;
    color: #E71930;
}

.number-input {
    display: inline;
    width: 4em;
    margin-right: 1em; 
}

@media (max-width: 640px) {
    .registration-body {
        text-align: left;
        width: 90%;
        margin: auto;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.button {
    margin-top: 3em;
    margin-left: 1em;
    margin-right: 1em;
}

.header-text {
  margin-top: 3em;
}

/* Credit https://loading.io/css/ */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 2px 10px;
    border: 4px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(185, 185, 185) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  